@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth !important;
}
.ScrollbarHide::-webkit-scrollbar {
  width: 0;
  height: 0;
}


::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px;    
  border: 2px solid #ffffff; 
}
scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
body {
  scroll-snap-type: y mandatory;
}

.animate-write-text {
  position: relative;
  margin: auto;
  padding-right: 1rem;
  padding-block: 1rem;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  animation: write 6s steps(50) infinite forwards;
}

/* .animate-write-text::after {
  content: "";
  width: 1rem;
  border-bottom: 4px solid white;
  position: absolute;
  right: 0;
  bottom: 1rem;
} */

.animate-write-text.delay-animation {
  animation-delay: 4000ms;
}

/* Keyframes for the writing animation */
@keyframes write {
  0% {
    width: 0; /* Start with zero width */
  }
  80%,
  100% {
    width: 100%; /* Write until full width */
  }
}
.showUser {
	display: none;
}
.userProfile:hover .showUser {
	display: block;
}
.message:hover .showMore {
	display: flex;
}
.showMore {
	display: none;
}
.scrollbar-hide::-webkit-scrollbar {
	display: none;
}
